import styled from 'styled-components';
import { Form } from 'antd';

/**
 * AntD Form.Item with our customized styles
 */

const FormItem = styled(Form.Item)`
	margin-bottom: 16px;

	svg {
		width: 16px;
		height: 16px;
		cursor: pointer;
		vertical-align: middle;
	}

	input {
		color: ${({ theme }) => theme.colors.text_dark};
		line-height: 16.41px;
	}

	// autocomplete styles
	input:-webkit-autofill {
		-webkit-text-fill-color: ${({ theme }) => theme.colors.text_dark};
	}

	.ant-input {
		box-sizing: border-box;
		height: 40px;
		padding: 12px 16px;
		border: 1px solid ${({ theme }) => theme.colors.input_border};
		border-radius: 8px;
		font-size: 14px;
		outline: none;
	}

	.ant-input-affix-wrapper {
		padding: 0;
		border-radius: 8px;
		border: 0px;
	}

	.ant-input-affix-wrapper > input.ant-input {
		padding: 12px 29px 12px 16px;
		border: 1px solid ${({ theme }) => theme.colors.input_border};
	}

	input::placeholder {
		color: ${({ theme }) => theme.colors.labels};
	}

	&.ant-form-item-with-help .ant-input {
		border-color: ${({ theme }) => theme.colors.error};
	}

	& .ant-form-item-label {
		padding-bottom: 4px;
		& > label {
			font-weight: 600;
			font-size: ${({ theme }) => theme.fonts.small};
			line-height: 18px;
			letter-spacing: 0.012em;
			color: ${({ theme }) => theme.colors.labels};
		}
	}
`;

export default FormItem;
