import { Spin } from 'antd';
import styled from 'styled-components';

export const StyledSpin = styled(Spin)`
	i {
		background-color: ${({ theme }) => theme.colors.active};
	}

	i:nth-child(2) {
		background-color: linear-gradient(
				0deg,
				rgba(255, 255, 255, 0.6),
				rgba(255, 255, 255, 0.6)
			),
			#6239b2;
	}
	i:nth-child(3) {
		background-color: linear-gradient(
				0deg,
				rgba(255, 255, 255, 0.4),
				rgba(255, 255, 255, 0.4)
			),
			#6239b2;
	}
	i:nth-child(4) {
		background-color: linear-gradient(
				0deg,
				rgba(255, 255, 255, 0.2),
				rgba(255, 255, 255, 0.2)
			),
			#6239b2;
	}
`;

export const LoadingIndicatorWrapper = styled.div<{
	fullScreen?: boolean;
}>`
	position: ${({ fullScreen }) => (fullScreen ? 'absolute' : 'unset')};
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: ${({ fullScreen }) =>
		fullScreen ? 'rgba(0, 0, 0, 0.15)' : 'transparent'};
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 999;
`;
