import { useState } from 'react';
import LoginCredentialsStep from './LoginCredentialsStep';
import LoginCodeVerificationStep from './LoginCodeVerificationStep';

const Login = () => {
	const [ephemeralToken, setEphemeralToken] = useState<string | null>(null);

	return ephemeralToken ? (
		<LoginCodeVerificationStep
			ephemeralToken={ephemeralToken}
			onClickLogo={setEphemeralToken}
		/>
	) : (
		<LoginCredentialsStep onSuccess={setEphemeralToken} />
	);
};

export default Login;
