import { AxiosRequestConfig, AxiosError } from 'axios';
import { useCallback, useState } from 'react';
import axiosInterceptors from './axiosInterceptors';

interface UseApiReturnType {
	isLoading: boolean;
	callApi: <T, E = Error>(
		apiConfig: AxiosRequestConfig
	) => Promise<{ response?: T; error?: E }>;
}

/**
 * to use the custom hook useApi to send a request you should import the useApi
 * and then pass the apiConfig as a argument to callApi
 */
export default function useApi(): UseApiReturnType {
	const [isLoading, setLoading] = useState(false);
	const axiosApiInstance = axiosInterceptors();

	const callApi = useCallback(
		async <T, E = Error>(
			apiConfig: AxiosRequestConfig
		): Promise<{
			response?: T;
			error?: E;
		}> => {
			setLoading(true);

			try {
				const responseReceived = await axiosApiInstance.request<T>(apiConfig);

				return { response: responseReceived.data };
			} catch (errorResponse) {
				const error = errorResponse as AxiosError<E>;

				return { error: error?.response?.data };
			} finally {
				setLoading(false);
			}
		},
		[axiosApiInstance]
	);

	return { isLoading, callApi };
}
