export enum AppRoute {
	TECH_PARTNERS = '/tech-partners',
	TECH_PARTNER = '/tech-partners/:techPartnerId',
	INVENTORY = '/inventory',
	DEALS = '/deals',
	CREATIVE_APPROVAL = '/creative-approval',
	REPORTS = '/reports',
	RESOURCES = '/resources',
	LOGIN = '/login'
}

export enum RouteName {
	TECH_PARTNERS = 'Tech Partners',
	INVENTORY = 'Inventory',
	DEALS = 'Deals',
	CREATIVE_APPROVAL = 'Creative Approval',
	REPORTS = 'Reports',
	RESOURCES = 'Resources'
}

export const HOME_PAGE = AppRoute.DEALS;
