import { Redirect, Route } from 'react-router-dom';
// eslint-disable-next-line import/no-extraneous-dependencies
import { RouteProps } from 'react-router';
import { useEffect, useMemo } from 'react';
import { UserRole } from 'models';
import { useAuth } from 'CustomHooks';
import { createNotification } from '../components';

type Props = {
	allowedRoles: UserRole[];
	redirectPath: string;
} & RouteProps;

const RoleProtectedRoute = ({ allowedRoles, redirectPath, ...rest }: Props) => {
	const {
		user: { roles }
	} = useAuth();

	const hasAccess = useMemo(
		() => allowedRoles.some((role) => roles.includes(role)),
		[roles, allowedRoles]
	);

	useEffect(() => {
		if (!hasAccess) {
			createNotification({
				description: 'You do not have access to view this page',
				notificationType: 'error'
			});
		}
	}, [hasAccess]);
	// eslint-disable-next-line react/jsx-props-no-spreading
	return hasAccess ? <Route {...rest} /> : <Redirect to={redirectPath} />;
};

export default RoleProtectedRoute;
