import styled from 'styled-components';
import { ReactComponent as logo } from 'assets/whiteLogo.svg';

const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 57px;
	background: ${({ theme }) => theme.gradients.navbar_gradient};
`;

const Logo = styled(logo)`
	width: 76px;
	height: 30px;
	margin-left: 24px;
`;

const Separator = styled.div`
	width: 1px;
	height: 32px;
	background-color: ${({ theme }) => theme.colors.white};
	opacity: 0.5;
`;

const Wrapper = styled.div`
	display: flex;
	align-items: center;
`;

export { Container, Logo, Separator, Wrapper };
