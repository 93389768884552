import { useEffect, useState } from 'react';
import { Prompt, useHistory, useLocation } from 'react-router-dom';
import * as H from 'history';
import ConfirmationModal from '../ConfirmationModal';

interface Props {
	active: boolean;
	variant?: 'edit' | 'create';
}

const LeavePromptModal = ({ active, variant }: Props) => {
	const [showModal, setShowModal] = useState(false);
	const [when, setWhen] = useState(false);
	const history = useHistory();
	const [nextPath, setNextPath] = useState('');
	const { pathname } = useLocation();

	const activity = variant === 'edit' ? 'editing' : 'creating';

	const handleCancel = () => {
		setShowModal(false);
		setWhen(false);
	};
	const handleConfirm = () => {
		setShowModal(false);
	};
	const handleRedirect = (location: H.Location) => {
		if (pathname !== location.pathname) {
			setNextPath(location.pathname);
			setShowModal(true);
		}
		return false;
	};
	useEffect(() => {
		setWhen(active);
		if (active) {
			window.onbeforeunload = () => true;
		} else {
			window.onbeforeunload = null;
		}

		return () => {
			window.onbeforeunload = null;
		};
	}, [active]);

	useEffect(() => {
		if (!when && nextPath) {
			history.push(nextPath);
		}
	}, [when]);

	return (
		<>
			<Prompt when={when} message={handleRedirect} />
			<ConfirmationModal
				visible={showModal}
				title='Close the page'
				confirmText={`Back to ${activity}`}
				cancelText='Leave changes'
				onConfirm={handleConfirm}
				onCancel={handleCancel}
			>
				There are <b>Unsaved Changes.</b>
				<br />
				If you would like to continue {activity}, press the &quot;Back to{' '}
				{activity}
				&quot; button
			</ConfirmationModal>
		</>
	);
};

LeavePromptModal.defaultProps = {
	variant: 'edit'
};

export default LeavePromptModal;
