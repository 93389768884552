import styled from 'styled-components';

export const StyledSortButton = styled.button`
	display: inline-flex;
	border: none;
	background: none;
	padding: 0;
	font-weight: inherit;
	font-size: inherit;
	line-height: inherit;
	letter-spacing: inherit;
	color: inherit;
	cursor: pointer;
	div {
		margin-left: 8px;
	}
`;

export const StyledIconsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border: none;
	background: none;
`;

export const StyledIconWrapper = styled.span<{ isActive: boolean }>`
	line-height: 0;
	height: 5px;
	color: ${({ isActive, theme }) =>
		isActive ? theme.colors.neutral_800 : theme.colors.disabled};
`;
