import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

const StyledNavLink = styled(NavLink)`
	color: ${({ theme }) => theme.colors.white};
	margin-right: 24px;
	font-weight: 500;
	text-decoration: none;
	border-bottom: 0px solid ${({ theme }) => theme.colors.secondary};
	transition: all 0.2s;

	&:hover,
	&.active {
		color: ${({ theme }) => theme.colors.secondary};
		border-bottom: 2px solid ${({ theme }) => theme.colors.secondary};
	}
`;

export default StyledNavLink;
