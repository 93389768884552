import styled from 'styled-components';
import { Input } from 'antd';

const StyledInput = styled(Input)`
	height: 40px;
	padding: 12px 16px;
	border: 1px solid ${({ theme }) => theme.colors.primary_100};
	border-radius: ${({ theme }) => theme.border_radius};
	background: ${({ theme }) => theme.colors.white};
	font-size: 14px;
	outline: none;

	&.ant-input-affix-wrapper-focused,
	&.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover,
	&:focus,
	&:hover {
		border-color: ${({ theme }) => theme.colors.primary_600};
		box-shadow: ${({ theme }) => theme.box_shadows.shadow_2};
		.ant-input-prefix {
			color: ${({ theme }) => theme.colors.primary_600};
		}
	}
	.ant-input-prefix {
		margin-left: -8px;
		margin-right: 10px;
		color: ${({ theme }) => theme.colors.primary_200};
	}

	input {
		background: none;
		line-height: 16px;
		letter-spacing: 0.012em;
		color: ${({ theme }) => theme.colors.placeholder};
		::placeholder {
			color: ${({ theme }) => theme.colors.placeholder};
		}
	}
`;

export default StyledInput;
