import styled from 'styled-components';
import { AlertTriangle } from 'react-feather';

export const MessageWrapper = styled.div`
	position: relative;
	left: 5px;
	margin-top: 4px;
	color: ${({ theme }) => theme.colors.error};
	display: inline-flex;
	vertical-align: middle;
	line-height: normal;
`;

export const StyledAlertTriangle = styled(AlertTriangle)`
	margin-right: 5px;
`;
