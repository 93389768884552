import styled from 'styled-components';

const Main = styled.main`
	min-height: ${({ theme }) => theme.sizes.full_height};
	background: ${({ theme }) => theme.gradients.main_gradient};
	border-radius: 12px 12px 0 0;
	overflow: hidden;
`;

export default Main;
