import React from 'react';
import { User } from './User';

export enum AuthActions {
	LOGIN_SUCCESS = 'AUTH_LOGIN',
	LOGOUT = 'AUTH_LOGOUT'
}

export interface LoginCodeVerificationStepAction {
	type: AuthActions.LOGIN_SUCCESS;
	payload: User & { token: string };
}

export interface LogoutAction {
	type: AuthActions.LOGOUT;
}

export type UserAuthActions = LoginCodeVerificationStepAction | LogoutAction;

export interface UserState {
	user: User;
	isLoggedIn: boolean;
}

export interface Context {
	state: UserState;
	dispatch: React.Dispatch<UserAuthActions>;
}
