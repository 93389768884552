import { AlertTriangle, Info } from 'react-feather';
import styled from 'styled-components';
import Theme from 'theme';

const StyledAlertTriangle = styled(AlertTriangle)`
	color: ${Theme.colors.error};
`;

const StyledInfo = styled(Info)`
	color: ${Theme.colors.success};
`;

export { StyledAlertTriangle, StyledInfo };
