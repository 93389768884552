import { useState } from 'react';
import { Form } from 'antd';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { AdFormat } from 'models';
import AdFormatsAPI, { CreateError } from 'api/adFormats';
import { useApi } from 'CustomHooks';
import { createNotification, LeavePromptModal } from 'components';
import { AppRoute } from 'routing';
import { ActionBar, ResourceWrapper } from '../../../components';
import AdFormatForm from '../components';

interface Props {
	techPartnerName: string;
	onSuccess: () => void;
}

const CreateAdFormat = ({ techPartnerName, onSuccess }: Props) => {
	const [isInvalid, setIsInvalid] = useState<boolean>(true);
	const [isTouched, setIsTouched] = useState(false);
	const { isLoading, callApi } = useApi();
	const [form] = Form.useForm();
	const history = useHistory();
	const {
		params: { techPartnerId }
	} = useRouteMatch<{ techPartnerId: string }>();
	const requiredFields = [
		'name',
		'content_type',
		'min_width',
		'max_width',
		'min_height',
		'max_height',
		'ratio',
		'ad_type',
		'position'
	];

	const handleChange = async (): Promise<void> => {
		setIsTouched(form.isFieldsTouched(requiredFields));
		if (!form.isFieldsTouched(requiredFields, true)) {
			setIsInvalid(true);
			return;
		}

		const hasErrors = form
			.getFieldsError()
			.some(({ errors }) => !!errors.length);
		setIsInvalid(hasErrors);
	};

	const handleSubmit = async () => {
		setIsTouched(false);
		const {
			name,
			content_type,
			min_width,
			max_width,
			min_height,
			max_height,
			ratio,
			ad_type,
			position
		} = form.getFieldsValue();
		const { response, error } = await callApi<AdFormat, CreateError>(
			AdFormatsAPI.create(
				name,
				content_type,
				min_width,
				max_width,
				min_height,
				max_height,
				ratio,
				ad_type,
				position,
				parseInt(techPartnerId, 10)
			)
		);

		if (error) {
			const allErrors = [
				...(error.non_field_errors || []),
				...(error.name || []),
				...(error.content_type || []),
				...(error.min_width || []),
				...(error.max_width || []),
				...(error.min_height || []),
				...(error.max_height || []),
				...(error.ratio || []),
				...(error.ad_type || []),
				...(error.position || [])
			];
			const errors = new Set(allErrors);
			errors.forEach((message) =>
				createNotification({
					description: message,
					notificationType: 'error'
				})
			);
		}

		if (response) {
			createNotification({
				description: 'Ad Format created successfully',
				notificationType: 'success'
			});
			onSuccess();
			history.push(
				`${AppRoute.TECH_PARTNERS}/${techPartnerId}/ad-formats/${response.id}`
			);
		}
	};

	return (
		<div data-testid='tech-partner-ad-format-create'>
			<LeavePromptModal active={isTouched} variant='create' />
			<ActionBar
				title={techPartnerName}
				disabled={isInvalid}
				loading={isLoading}
				onSave={handleSubmit}
			/>
			<ResourceWrapper resourceName='New Ad Format'>
				<AdFormatForm form={form} onChange={handleChange} />
			</ResourceWrapper>
		</div>
	);
};

export default CreateAdFormat;
