import { ReactNode } from 'react';
import {
	StyledWrapper,
	StyledHeader,
	StyledContent
} from './ResourceWrapper.styled';

interface Props {
	resourceName: string;
	children: ReactNode;
}

const ResourceWrapper = ({ resourceName, children }: Props) => (
	<StyledWrapper>
		<StyledHeader>
			<span data-testid='tech-partner-resource-name'>{resourceName}</span>
		</StyledHeader>
		<StyledContent>{children}</StyledContent>
	</StyledWrapper>
);
export default ResourceWrapper;
