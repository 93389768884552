import styled from 'styled-components';
import { Button as AntButton } from 'antd';

const StyledAntButton = styled(AntButton)<{ variant: 'primary' | 'secondary' }>`
	height: 40px;
	padding: 8px 24px;
	font-size: 16px;
	font-weight: 500;
	border-radius: 8px;
	color: ${({ theme, variant }) =>
		variant === 'primary' ? theme.colors.white : theme.colors.labels};
	background: ${({ theme, variant }) =>
		variant === 'primary' ? theme.colors.primary : 'transparent'};
	border: ${({ theme, variant }) =>
		variant === 'primary' ? 'none' : `2px solid ${theme.colors.text_light}`};
	line-height: 19px;
	cursor: pointer;

	&:hover,
	&:focus,
	&:active {
		color: ${({ theme, variant }) =>
			variant === 'primary' ? theme.colors.white : theme.colors.labels};
		background: ${({ theme, variant }) =>
			variant === 'primary' ? theme.colors.primary : 'transparent'};
		border: ${({ theme, variant }) =>
			variant === 'primary' ? 'none' : `2px solid ${theme.colors.text_light}`};
	}

	&.ant-btn:disabled {
		&,
		&:hover,
		&:active,
		&:focus {
			color: ${({ theme, variant }) =>
				variant === 'primary' ? theme.colors.white : theme.colors.disabled};
			background: ${({ theme, variant }) =>
				variant === 'primary'
					? theme.colors.primary_100
					: theme.backgrounds.buttons.secondary.disabled};
		}
	}
`;

export default StyledAntButton;
