import React from 'react';
import { ThemeProvider } from 'styled-components';
import { Router } from 'react-router-dom';
import { AuthProvider } from 'providers';
import ErrorBoundary from 'ErrorBoundary';
import Theme from 'theme';
import history from 'routerHistory';

interface Props {
	children: React.ReactNode;
}

const AppProviders = ({ children }: Props) => (
	<ErrorBoundary>
		<ThemeProvider theme={Theme}>
			<AuthProvider>
				<Router history={history}>{children}</Router>
			</AuthProvider>
		</ThemeProvider>
	</ErrorBoundary>
);

export default AppProviders;
