import { useState } from 'react';
import { Form } from 'antd';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Page } from 'models';
import { useApi } from 'CustomHooks';
import { createNotification, LeavePromptModal } from 'components';
import PagesAPI, { CreateError } from 'api/pages';
import { AppRoute } from 'routing';
import { ActionBar, ResourceWrapper } from '../../../components';
import PageForm from '../components';

interface Props {
	techPartnerName: string;
	onSuccess: () => void;
}
const CreatePage = ({ techPartnerName, onSuccess }: Props) => {
	const [form] =
		Form.useForm<{ name: string; description: string; path: string }>();
	const [isInvalid, setIsInvalid] = useState(true);
	const [isTouched, setIsTouched] = useState(false);
	const { isLoading, callApi } = useApi();
	const history = useHistory();
	const {
		params: { techPartnerId }
	} = useRouteMatch<{ techPartnerId: string }>();
	const handleChange = async () => {
		setIsTouched(form.isFieldsTouched(['name', 'path', 'description']));
		if (!form.isFieldsTouched(['name', 'path'], true)) {
			setIsInvalid(true);
			return;
		}
		const hasErrors = form
			.getFieldsError()
			.some(({ errors }) => !!errors.length);
		setIsInvalid(hasErrors);
	};

	const handleSubmit = async () => {
		setIsTouched(false);
		const { name, description, path } = form.getFieldsValue();
		const { response, error } = await callApi<Page, CreateError>(
			PagesAPI.create(name, description, path, parseInt(techPartnerId, 10))
		);

		if (error) {
			[
				...(error.non_field_errors || []),
				...(error.description || []),
				...(error.name || []),
				...(error.path || [])
			].forEach((message) =>
				createNotification({
					description: message,
					notificationType: 'error'
				})
			);
		}

		if (response) {
			createNotification({
				description: 'Page created successfully',
				notificationType: 'success'
			});
			onSuccess();
			history.push(
				`${AppRoute.TECH_PARTNERS}/${techPartnerId}/pages/${response.id}`
			);
		}
	};

	return (
		<div data-testid='tech-partner-page-create'>
			<LeavePromptModal active={isTouched} variant='create' />
			<ActionBar
				title={techPartnerName}
				disabled={isInvalid}
				loading={isLoading}
				onSave={handleSubmit}
			/>
			<ResourceWrapper resourceName='New Page'>
				<PageForm form={form} onChange={handleChange} />
			</ResourceWrapper>
		</div>
	);
};

export default CreatePage;
