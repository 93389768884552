import styled from 'styled-components';
import { Modal } from 'antd';

const StyledModal = styled(Modal)`
	top: 64px;
	display: flex;
	justify-content: center;
	.ant-modal-content {
		border-radius: 16px;
		overflow: hidden;
	}

	.ant-modal-header {
		font-family: 'Poppins', sans-serif;
		padding: 48px 48px 0;
		border: none;
		color: ${({ theme }) => theme.colors.primary_1000};
		.ant-modal-title {
			font-weight: 600;
			font-size: 24px;
			line-height: 36px;
			letter-spacing: 0.012em;
		}
	}

	.ant-modal-body {
		font-weight: 300;
		font-size: 14px;
		line-height: 21px;
		letter-spacing: 0.012em;
		font-family: 'Poppins', sans-serif;
		color: ${({ theme }) => theme.colors.neutral_800};
		padding: 24px 48px 48px;
		white-space: nowrap;
	}

	.ant-modal-footer {
		display: flex;
		justify-content: space-between;
		padding: 48px;
		border: none;
		background: ${({ theme }) => theme.colors.modal_footer};
	}
`;

export default StyledModal;
