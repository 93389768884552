import {
	BrowserRouter as Router,
	Switch,
	Route,
	Redirect
} from 'react-router-dom';
import { Navbar } from 'components';
import { Deals, Login, TechPartners, TechPartner } from 'Views';
import { useAuth } from 'CustomHooks';
import { AppRoute, HOME_PAGE } from './AppRoute.enum';
import Main from './AppRoutes.styled';
import ProtectedRoutes from './ProtectedRoutes';

const AppRoutes = () => {
	const { isLoggedIn } = useAuth();

	return (
		<Router>
			<Route exact path={AppRoute.LOGIN}>
				{isLoggedIn ? <Redirect to={HOME_PAGE} /> : <Login />}
			</Route>
			<ProtectedRoutes isLoggedIn={isLoggedIn}>
				<Navbar />
				<Main>
					{/* A <Switch> looks through its children <Route>s and
              renders the first one that matches the current URL. */}
					<Switch>
						<Route path={AppRoute.TECH_PARTNER}>
							<TechPartner />
						</Route>
						<Route path={AppRoute.INVENTORY} />
						<Route path={AppRoute.DEALS}>
							<Deals />
						</Route>
						<Route path={AppRoute.CREATIVE_APPROVAL} />
						<Route path={AppRoute.REPORTS} />
						<Route path={AppRoute.RESOURCES} />
						<Route path={AppRoute.TECH_PARTNERS} exact>
							<TechPartners />
						</Route>
						<Redirect to={HOME_PAGE} />
					</Switch>
				</Main>
			</ProtectedRoutes>
		</Router>
	);
};

export default AppRoutes;
