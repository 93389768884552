import { Form, FormInstance, Input } from 'antd';
import { StyledFormItem } from 'Views/TechPartner/components';
import { ValidationMessage } from 'components';

const { TextArea } = Input;
interface Props {
	onChange: () => void;
	form?: FormInstance;
}

const PageForm = ({ form, onChange }: Props) => (
	<Form
		form={form}
		data-testid='tech-partner-page-form'
		layout='vertical'
		onFieldsChange={onChange}
		requiredMark={false}
	>
		<StyledFormItem
			label='Page Name'
			name='name'
			rules={[
				{
					required: true,
					message: (
						<ValidationMessage validationMessage='Please input your page name' />
					)
				}
			]}
		>
			<Input data-testid='tech-partner-page-name-input' />
		</StyledFormItem>
		<StyledFormItem label='Description (optional)' name='description'>
			<TextArea autoSize data-testid='tech-partner-page-description-input' />
		</StyledFormItem>
		<StyledFormItem
			label='Path'
			name='path'
			rules={[
				{
					required: true,
					message: (
						<ValidationMessage validationMessage='Please input your page path' />
					)
				}
			]}
		>
			<Input data-testid='tech-partner-page-path-input' />
		</StyledFormItem>
	</Form>
);

PageForm.defaultProps = {
	form: undefined
};

export default PageForm;
