import React from 'react';
import { Form, Input } from 'antd';
import { ActionBar, ResourceWrapper, StyledFormItem } from '../../components';

const { TextArea } = Input;

interface Props {
	techPartnerName: string;
	techPartnerDescription: string;
}

const TechPartnerDetails = ({
	techPartnerName,
	techPartnerDescription
}: Props) => (
	<div data-testid='tech-partner-details'>
		<ActionBar title={techPartnerName} disabled />
		<ResourceWrapper resourceName='Tech Partner'>
			<Form layout='vertical'>
				<StyledFormItem label="Tech Partner's Name">
					<Input
						placeholder="Tech Partner's Name"
						disabled
						value={techPartnerName}
						data-testid='tech-partner-details-name-input'
					/>
				</StyledFormItem>
				<StyledFormItem label='Description'>
					<TextArea
						placeholder='Description'
						disabled
						autoSize
						value={techPartnerDescription}
						data-testid='tech-partner-details-description-input'
					/>
				</StyledFormItem>
			</Form>
		</ResourceWrapper>
	</div>
);
export default TechPartnerDetails;
