import { Request } from '../request';

export interface GetListError {
	non_field_errors: string[];
}

interface GetListRequest extends Request {
	params: {
		search?: string;
		ordering?: string;
	};
}

export const getList = (search?: string, ordering?: string): GetListRequest => {
	const request: GetListRequest = {
		method: 'get',
		url: 'api/tech-partners/',
		params: {}
	};

	request.params = {
		...(search && { search }),
		...(ordering && { ordering })
	};
	return request;
};
