import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import './index.css';
import { AppProviders } from 'providers';
import { version } from '../package.json';
import App from './App';
import reportWebVitals from './reportWebVitals';
// It will disable annoying async validator warnings in console. Async validator is used by antD form.
// https://github.com/yiminghe/async-validator#how-to-avoid-warning
// eslint-disable-next-line
import Schema from 'async-validator';
// eslint-disable-next-line
(Schema as any).warning = function () {};

if (process.env.REACT_APP_SENTRY_DSN) {
	Sentry.init({
		dsn: process.env.REACT_APP_SENTRY_DSN,
		integrations: [new Integrations.BrowserTracing()],
		// Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring
		tracesSampleRate: parseFloat(
			process.env.REACT_APP_VERSION_SENTRY_TRACES_SAMPLE_RATE || '1.0'
		),
		// Remember that source maps uploaded to sentry are related to a specific release version
		release: version
	});
}

ReactDOM.render(
	<React.StrictMode>
		<AppProviders>
			<App />
		</AppProviders>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
