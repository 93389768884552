import { Empty, Table } from 'antd';
import {
	ChangeEvent,
	PropsWithChildren,
	useCallback,
	useEffect,
	useMemo,
	useState
} from 'react';
import { Search } from 'react-feather';
import { debounce } from 'lodash';
import {
	ListViewWrapper,
	createNotification,
	LoadingIndicator
} from 'components';
import { TechPartner } from 'models';
import TechPartnersAPI, { GetListError } from 'api/techPartners';
import { useApi } from 'CustomHooks';
import { AppRoute } from 'routing';
import {
	StyledCell,
	StyledRow,
	StyledTableWrapper,
	StyledSearchInput,
	StyledNameLink
} from './TechPartners.styled';
import { TechPartnerDropdown, SortButton } from './components';

const columns = [
	{
		title: 'Name',
		dataIndex: 'name',
		key: 'name',
		width: '30%',
		render: (text: string, record: TechPartner) => (
			<StyledNameLink to={`${AppRoute.TECH_PARTNERS}/${record.id}`}>
				{text}
			</StyledNameLink>
		)
	},
	{
		title: 'Ad format Count',
		dataIndex: 'ad_format_count',
		key: 'ad_format_count',
		width: '20%'
	},
	{
		width: '50%',
		render: (text: string, record: TechPartner) => (
			<TechPartnerDropdown id={record.id} />
		)
	}
];

const TechPartners = () => {
	const [techPartners, setTechPartners] = useState<TechPartner[]>([]);
	const [searchQuery, setSearchQuery] = useState('');
	const [ordering, setOrdering] = useState('');
	const { callApi, isLoading } = useApi();

	const components = useMemo(
		() => ({
			header: {
				wrapper: (props: PropsWithChildren<{}>) => (
					<thead>{props.children}</thead>
				),
				row: () => (
					<tr>
						<th>
							<SortButton
								dataIndex='name'
								value={ordering}
								onChange={setOrdering}
							>
								Name
							</SortButton>
						</th>
						<th>
							<SortButton
								dataIndex='ad_format_count'
								value={ordering}
								onChange={setOrdering}
							>
								Ad Format Count
							</SortButton>
						</th>
						<th aria-label='actions' />
					</tr>
				)
			},
			body: {
				wrapper: (props: PropsWithChildren<{}>) => (
					<tbody>{props.children}</tbody>
				),
				row: (props: PropsWithChildren<{}>) => (
					<StyledRow data-testid='tech-partner-row-item'>
						{props.children}
					</StyledRow>
				),
				cell: ({
					children,
					colSpan,
					rowSpan
				}: PropsWithChildren<{ colSpan?: number; rowSpan?: number }>) => (
					<StyledCell colSpan={colSpan || 1} rowSpan={rowSpan || 1}>
						{children}
					</StyledCell>
				)
			}
		}),
		[ordering]
	);

	const loadTechPartners = async (): Promise<void> => {
		const { response, error } = await callApi<TechPartner[], GetListError>(
			TechPartnersAPI.getList(searchQuery, ordering)
		);

		if (response) {
			setTechPartners(response);
		}

		if (error) {
			error.non_field_errors.forEach((errorMessage) => {
				createNotification({
					description: errorMessage,
					notificationType: 'error'
				});
			});
		}
	};

	const handleSearchChange = useCallback(
		debounce(
			(e: ChangeEvent<HTMLInputElement>) => setSearchQuery(e.target.value),
			500
		),
		[]
	);

	useEffect(() => {
		loadTechPartners();
	}, [searchQuery, ordering]);
	return (
		<>
			{isLoading && <LoadingIndicator fullScreen />}

			<ListViewWrapper title='Tech Partners'>
				<StyledSearchInput
					data-testid='tech-partners-search-input'
					prefix={<Search size={16} />}
					placeholder='Search'
					onChange={handleSearchChange}
				/>

				<StyledTableWrapper>
					<Table<TechPartner>
						locale={{
							emptyText: (
								<Empty
									image={Empty.PRESENTED_IMAGE_SIMPLE}
									description={isLoading ? 'Loading Data...' : 'No Data'}
								/>
							)
						}}
						dataSource={techPartners}
						columns={columns}
						data-testid='tech-partners-list'
						pagination={false}
						components={components}
						rowKey='id'
					/>
				</StyledTableWrapper>
			</ListViewWrapper>
		</>
	);
};

export default TechPartners;
