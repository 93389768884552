import { Input } from 'antd';
import React from 'react';
import { ReactComponent as MailShadowImg } from 'assets/mailShadowImg.svg';
import {
	loginCodeVerificationStepRequest,
	LoginCodeVerificationStepResponse,
	LoginCodeVerificationStepError
} from 'api';
import { useApi, useAuth } from 'CustomHooks';
import { createNotification } from 'components';
import {
	Container,
	MailImageWrapper,
	StyledMailImg,
	VerificationWrapper,
	Header,
	VerificationInfo,
	StyledForm,
	StyledFormItem,
	VerifyButton,
	Logo
} from './LoginCodeVerificationStep.styled';

interface Values {
	code: string;
}

interface Props {
	ephemeralToken: string | null;
	onClickLogo: React.Dispatch<React.SetStateAction<string | null>>;
}

const LoginCodeVerificationStep = ({ ephemeralToken, onClickLogo }: Props) => {
	const { isLoading, callApi } = useApi();
	const { loginSuccess } = useAuth();

	const onSubmit = async (values: unknown): Promise<void | null> => {
		const { code } = values as Values;

		const { response, error } = await callApi<
			LoginCodeVerificationStepResponse,
			LoginCodeVerificationStepError
		>(loginCodeVerificationStepRequest(ephemeralToken, code));

		if (response) {
			const { token, username, last_name, first_name, roles, permissions } =
				response;

			loginSuccess({
				token,
				username,
				last_name,
				first_name,
				roles,
				permissions
			});
		}

		if (error) {
			return createNotification({
				description: error.code || error.non_field_errors[0],
				notificationType: 'error'
			});
		}

		return null;
	};

	return (
		<Container>
			<MailImageWrapper>
				<StyledMailImg data-testid='login-code-verification-step-mail-img' />
				<MailShadowImg data-testid='login-code-verification-step-mail-shadow-img' />
			</MailImageWrapper>
			<VerificationWrapper>
				<Logo
					onClick={() => onClickLogo(null)}
					data-testid='login-code-verification-step-logo'
				/>
				<Header>Two Factor Authentication</Header>
				<VerificationInfo>
					A verification code has been sent to your email.{' '}
					<b>This code will be valid for 15 minutes.</b>
				</VerificationInfo>
				<StyledForm onFinish={onSubmit}>
					<StyledFormItem name='code'>
						<Input
							placeholder='Please enter the code here'
							data-testid='login-code-verification-input'
						/>
					</StyledFormItem>
					<VerifyButton
						variant='primary'
						htmlType='submit'
						data-testid='login-verify-button'
						loading={isLoading}
					>
						Verify
					</VerifyButton>
				</StyledForm>
			</VerificationWrapper>
		</Container>
	);
};

export default LoginCodeVerificationStep;
