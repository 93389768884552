// Why we need this component? https://reactjs.org/docs/error-boundaries.html#introducing-error-boundaries
import React from 'react';

interface ErrorBoundaryProps {
	children: React.ReactNode;
}

interface ErrorBoundaryState {
	hasError: boolean;
}

class ErrorBoundary extends React.Component<
	ErrorBoundaryProps,
	ErrorBoundaryState
> {
	constructor(props: Readonly<ErrorBoundaryProps>) {
		super(props);
		this.state = { hasError: false };
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	static getDerivedStateFromError(error: Error): { hasError: boolean } {
		return { hasError: true };
	}

	// Catch errors in any components below and re-render with error message
	// You can also log error messages to an error reporting service here
	// componentDidCatch(error: Error, errorInfo: ErrorInfo): void {}

	render(): JSX.Element | React.ReactNode {
		const { hasError } = this.state;
		const { children } = this.props;
		if (hasError) {
			// You can render any custom fallback UI
			return <h1>Something went wrong.</h1>;
		}

		// Normally, just render children
		return children;
	}
}

export default ErrorBoundary;
