import React from 'react';
import { Input } from 'antd';
import {
	createNotification,
	ValidationMessage,
	FormItem,
	Button
} from 'components';
import { useApi } from 'CustomHooks';
import {
	loginCredentialsStepRequest,
	LoginCredentialsStepResponse,
	LoginCredentialsStepError
} from 'api';
import {
	Container,
	StyledForm,
	Logo,
	StyledEye,
	StyledEyeOff,
	StyledInputPassword
} from './LoginCredentialsStep.styled';

interface Values {
	email: string;
	password: string;
}

interface Props {
	onSuccess: React.Dispatch<React.SetStateAction<string | null>>;
}

const LoginCredentialsStep = ({ onSuccess }: Props) => {
	const { isLoading, callApi } = useApi();

	const onSubmit = async (values: unknown): Promise<void | null> => {
		const { email, password } = values as Values;
		const { response, error } = await callApi<
			LoginCredentialsStepResponse,
			LoginCredentialsStepError
		>(loginCredentialsStepRequest(email, password));

		if (response) {
			onSuccess(response.ephemeral_token);
		}

		if (error) {
			return createNotification({
				description: error.non_field_errors[0],
				notificationType: 'error'
			});
		}

		return null;
	};

	return (
		<Container>
			<Logo data-testid='login-credentials-step-logo' />
			<StyledForm layout='vertical' onFinish={onSubmit}>
				<FormItem
					name='email'
					rules={[
						{
							required: true,
							message: (
								<ValidationMessage validationMessage='Please input your email address' />
							)
						},
						{
							type: 'email',
							message: (
								<ValidationMessage validationMessage='Please input valid email address' />
							)
						}
					]}
				>
					<Input placeholder='Login' data-testid='login-email-input' />
				</FormItem>

				<FormItem
					name='password'
					rules={[
						{
							required: true,
							message: (
								<ValidationMessage validationMessage='Please input your password' />
							)
						}
					]}
				>
					<StyledInputPassword
						placeholder='Password'
						data-testid='login-password-input'
						iconRender={(visible) =>
							visible ? <StyledEye /> : <StyledEyeOff />
						}
					/>
				</FormItem>
				<Button
					variant='primary'
					htmlType='submit'
					data-testid='login-submit-button'
					loading={isLoading}
				>
					Login
				</Button>
			</StyledForm>
		</Container>
	);
};

export default LoginCredentialsStep;
