import { User } from 'models';

export interface LoginCodeVerificationStepResponse extends User {
	token: string;
}

export interface LoginCodeVerificationStepError {
	code: string;
	non_field_errors: string[];
}

interface LoginCodeVerificationStepRequest {
	method: 'post';
	url: 'api/auth/login/code/';
	data: {
		ephemeral_token: string | null;
		code: string;
	};
}

export const loginCodeVerificationStepRequest = (
	ephemeral_token: string | null,
	code: string
): LoginCodeVerificationStepRequest => ({
	method: 'post',
	url: 'api/auth/login/code/',
	data: {
		ephemeral_token,
		code
	}
});
