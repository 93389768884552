import styled from 'styled-components';
import { FormItem } from 'components';

const StyledFormItem = styled(FormItem)`
	margin-bottom: 24px;

	.ant-input {
		max-width: 520px;
	}
`;

export default StyledFormItem;
