// Here you can define colors, margins, paddings, borders, shadows and stuff you will use across whole project.

const Theme = {
	colors: {
		active: '#6239B2',
		primary: '#4F387D',
		secondary: '#FFD9BF',
		white: '#ffffff',
		text_light: '#BFBFBF',
		text_normal: '#808080',
		text_dark: '#404040',
		neutral_200: '#BFBFBF',
		neutral_300: '#A6A6A6',
		neutral_400: '#808080',
		neutral_800: '#404040',
		primary_100: '#CBC6D0',
		primary_200: '#958BA2',
		primary_600: '#4F387D',
		primary_800: '#2E1C45',
		primary_1000: '#271C34',
		secondary_100: '#FFD9BF',
		secondary_200: '#FFB580',
		secondary_400: '#FF8F40',
		secondary_600: '#FF6B00',
		placeholder: '#404040',
		labels: '#A6A6A6',
		input_border: '#CBC6D0',
		error: '#EB6178',
		success: '#20b56e',
		hover: '#F6F4F9',
		disabled: '#BFBFBF',
		selected_text: '#808080',
		modal_footer: '#F9F9FF'
	},
	fonts: {
		small: '12px',
		large: '18px',
		extra_large: '24px'
	},
	gradients: {
		main_gradient: 'linear-gradient(90deg, #FDFDFD 0%, #F0F3FB 100%)',
		navbar_gradient: 'linear-gradient(90deg, #2e1c45 0%, #4f387d 100%)'
	},
	backgrounds: {
		buttons: {
			secondary: {
				disabled: 'rgba(177, 180, 193, 0.25)'
			}
		}
	},
	sizes: {
		full_height: 'calc(100vh - 57px)'
	},
	box_shadows: {
		shadow_2:
			'0px -4px 8px -4px rgba(223, 222, 255, 0.4), 0px 4px 16px -8px rgba(72, 69, 174, 0.2)'
	},
	border_radius: '8px'
};

const size = {
	tablet_small: '600px',
	tablet_medium: '905px',
	laptop: '1240px',
	desktop: '1440px'
};

export const device = {
	tablet_small: `(min-width: ${size.tablet_small})`,
	tablet_medium: `(min-width: ${size.tablet_medium})`,
	laptop: `(min-width: ${size.laptop})`,
	desktop: `(min-width: ${size.desktop})`
};

export default Theme;
