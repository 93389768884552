import { Layout, Menu } from 'antd';
import styled from 'styled-components';

export const StyledMenu = styled(Menu)`
	height: 100%;
	overflow: auto;
	position: fixed;
	width: 248px;
	border-radius: 12px 0 0 0;

	&.ant-menu:not(.ant-menu-horizontal) .ant-menu-item {
		font-weight: bold;
	}

	&.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
		background-color: transparent;
		color: ${({ theme }) => theme.colors.active};
	}

	.ant-menu-item a:hover {
		color: ${({ theme }) => theme.colors.neutral_800};
	}

	.ant-menu-item-selected a,
	.ant-menu-item-selected a:hover {
		font-weight: bold;
		color: ${({ theme }) => theme.colors.active};
	}

	&.ant-menu-inline .ant-menu-item::after {
		border-right: none;
	}

	&.ant-menu-light .ant-menu-item:hover,
	&.ant-menu-light .ant-menu-item-active,
	&.ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
	&.ant-menu-light .ant-menu-submenu-active,
	&.ant-menui-lighting-color .ant-menu-submenu-title:hover {
		background: inherit;
		color: inherit;
	}

	&.ant-menu-inline .ant-menu-submenu-open .ant-menu-submenu-title {
		margin-bottom: 0;
	}
`;

export const StyledSider = styled(Layout.Sider)`
	position: sticky;
	top: 0px;
	bottom: -57px;
	overflow-y: auto;
	height: 100vh;
	max-height: ${({ theme }) => theme.sizes.full_height};
	box-shadow: 0 0 16px #e2e1e4;
	z-index: 0;

	& ul {
		position: relative;
		z-index: 2;
	}
`;

export const StyledContent = styled(Layout.Content)`
	padding: 32px 80px 0 52px;
	background: ${({ theme }) => theme.gradients.main_gradient};
`;

export const StyledSubMenu = styled(Menu.SubMenu)`
	.ant-menu-submenu-title {
		color: ${({ theme }) => theme.colors.neutral_800};
		font-weight: bold;
	}

	&.ant-menu-submenu-active > .ant-menu-submenu-title {
		background-color: inherit;
	}

	&.ant-menu-submenu:not(.ant-menu-submenu-horizontal) .ant-menu-item {
		font-weight: normal;
	}

	&.ant-menu-submenu:not(.ant-menu-submenu-horizontal) .ant-menu-item-selected {
		background-color: ${({ theme }) => theme.colors.hover};
		color: ${({ theme }) => theme.colors.active};
	}

	.ant-menu-item-selected {
		border-left: 3px solid ${({ theme }) => theme.colors.active};
	}

	.ant-menu-sub.ant-menu-inline {
		background: transparent;
	}

	&.ant-menu-submenu-selected {
		font-weight: bold;
		color: ${({ theme }) => theme.colors.active};
	}

	.ant-menu-sub > .ant-menu-item-selected a,
	.ant-menu-sub > .ant-menu-item-selected a:hover {
		font-weight: normal;
	}

	.ant-menu-submenu-title {
		padding-right: 24px;
	}

	&.ant-menu-submenu
		.ant-menu-submenu-inline
		.ant-menu-submenu-open
		> .ant-menu-vertical
		.ant-menu-item,
	.ant-menu-inline .ant-menu-item,
	.ant-menu-vertical .ant-menu-submenu-title,
	.ant-menu-inline .ant-menu-submenu-title {
		margin: 0;
	}

	&.ant-menu-submenu-selected > .ant-menu-submenu-title,
	&.ant-menu-submenu-selected > .ant-menu-submenu-expand-icon,
	.ant-menu-submenu-arrow {
		color: ${({ theme }) => theme.colors.active};
	}

	&.ant-menu-submenu > .ant-menu-submenu-title > .ant-menu-submenu-expand-icon,
	&.ant-menu-submenu:hover
		> .ant-menu-submenu-title
		> .ant-menu-submenu-expand-icon,
	&.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
		color: ${({ theme }) => theme.colors.active};
	}

	&.ant-menu-submenu-active:not(.ant-menu-submenu-selected)
		> .ant-menu-submenu-title,
	&.ant-menu-submenu-active:not(.ant-menu-submenu-selected)
		> .ant-menu-submenu-expand-icon,
	.ant-menu-submenu-arrow {
		padding-right: 16px;
		color: ${({ theme }) => theme.colors.neutral_800};
	}
`;

/**
 * Using !important is not a good practice, but in this case there is no other way to override the antD styles
 */

export const StyledMenuActionItem = styled(Menu.Item)`
	padding-left: 24px !important;
	color: ${({ theme }) => theme.colors.primary_1000};

	svg {
		vertical-align: middle;
		margin-right: 7px;
	}
`;
