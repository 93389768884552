import React, { useReducer } from 'react';
import {
	AuthContext,
	preserveToken,
	removeToken,
	isTokenExisting,
	preserveUserData,
	removeUserData,
	getPreservedUserData
} from 'contexts';
import { UserState, UserAuthActions, AuthActions } from 'models';

interface Props {
	children: React.ReactNode;
}

const initialState = {
	user: getPreservedUserData(),
	isLoggedIn: isTokenExisting()
};

const authReducer = (state: UserState, action: UserAuthActions): UserState => {
	switch (action.type) {
		case AuthActions.LOGIN_SUCCESS:
			preserveToken(action.payload.token);
			preserveUserData({
				username: action.payload.username,
				first_name: action.payload.first_name,
				last_name: action.payload.last_name,
				roles: action.payload.roles,
				permissions: action.payload.permissions
			});

			return {
				...state,
				user: {
					username: action.payload.username,
					first_name: action.payload.first_name,
					last_name: action.payload.last_name,
					roles: action.payload.roles,
					permissions: action.payload.permissions
				},
				isLoggedIn: true
			};
		case AuthActions.LOGOUT:
			removeToken();
			removeUserData();
			return {
				...initialState,
				isLoggedIn: false
			};
		default:
			return state;
	}
};

const AuthProvider = ({ children }: Props) => {
	const [state, dispatch] = useReducer(authReducer, initialState);
	const value = {
		state,
		dispatch
	};

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
