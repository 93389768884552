const ValidationMessages = {
	name: 'Please input your Ad Format name',
	type: 'Please select your content type of Ad Type',
	min_width: 'Please input your Ad Format minimum width',
	max_width: 'Please input your Ad Format maximum width',
	min_height: 'Please input your Ad Format minimum height',
	max_height: 'Please input your Ad Format maximum height',
	ratio: 'Please input your Ad Format ratio',
	ad_type: 'Please input your Ad Format type',
	position: 'Please input your Ad Format position'
};

export default ValidationMessages;
