import {
	Link,
	Route,
	Switch,
	useHistory,
	useRouteMatch,
	useLocation
} from 'react-router-dom';
import { Layout, Menu } from 'antd';
import { useEffect, useState } from 'react';
import { Plus } from 'react-feather';
import PagesAPI from 'api/pages';
import AdFormatsAPI from 'api/adFormats';
import { useApi, useAuth } from 'CustomHooks';
import { AppRoute, RoleProtectedRoute } from 'routing';
import {
	TechPartner as TechPartnerModel,
	Page,
	UserRole,
	AdFormat
} from 'models';
import TechPartnersAPI from 'api/techPartners';
import { LoadingIndicator, createNotification } from 'components';
import {
	StyledContent,
	StyledMenu,
	StyledSider,
	StyledSubMenu,
	StyledMenuActionItem
} from './TechPartner.styled';
import { CreateAdFormat, CreatePage, TechPartnerDetails } from './views';
import TechPartnerRoute from './TechPartnerRoute.enum';

const TechPartner = () => {
	const {
		path,
		url,
		params: { techPartnerId }
	} = useRouteMatch<{ techPartnerId: string }>();
	const [techPartner, setTechPartner] = useState<TechPartnerModel | undefined>(
		undefined
	);
	const { callApi } = useApi();
	const history = useHistory();
	const [pages, setPages] = useState<Page[]>([]);
	const [adFormats, setAdFormats] = useState<AdFormat[]>([]);
	const [isLoadingAdFormats, setIsLoadingAdFormats] = useState<Boolean>(true);
	const [isLoadingPages, setIsLoadingPages] = useState<Boolean>(true);
	const { pathname } = useLocation();
	const { isAdmin } = useAuth();

	const loadPages = async (): Promise<void> => {
		const { response, error } = await callApi<Page[]>(
			PagesAPI.getList(techPartnerId)
		);

		if (error) {
			createNotification({
				description: 'Cannot get pages',
				notificationType: 'error'
			});
		}

		if (response) {
			setPages(response);
		}

		setIsLoadingPages(false);
	};

	const loadAdFormats = async (): Promise<void> => {
		const { response, error } = await callApi<AdFormat[]>(
			AdFormatsAPI.getList(techPartnerId)
		);

		if (error) {
			createNotification({
				description: 'Cannot get Ad Formats',
				notificationType: 'error'
			});
		}

		if (response) {
			setAdFormats(response);
		}

		setIsLoadingAdFormats(false);
	};

	const loadTechPartner = async (): Promise<void> => {
		const { response, error } = await callApi<TechPartnerModel>(
			TechPartnersAPI.getOne(techPartnerId)
		);

		if (error) {
			history.push(AppRoute.TECH_PARTNERS);
			createNotification({
				description: 'Cannot get tech partner details',
				notificationType: 'error'
			});
		}

		if (response) {
			setTechPartner(response);
			loadPages();
			loadAdFormats();
		}
	};

	useEffect(() => {
		loadTechPartner();
	}, [techPartnerId]);

	if (!techPartner) return <LoadingIndicator fullScreen />;

	return (
		<Layout>
			<StyledSider width={248}>
				<StyledMenu
					mode='inline'
					defaultSelectedKeys={[`${AppRoute.TECH_PARTNERS}/${techPartnerId}`]}
					selectedKeys={[pathname]}
				>
					<Menu.Item key={`${AppRoute.TECH_PARTNERS}/${techPartnerId}`}>
						<Link
							data-testid='tech-partner-link-to-tech-partner-form'
							to={`${AppRoute.TECH_PARTNERS}/${techPartnerId}`}
						>
							Tech partner
						</Link>
					</Menu.Item>
					<StyledSubMenu title='Ad Formats' key='AdFormats'>
						{isAdmin && (
							<StyledMenuActionItem
								key={`${AppRoute.TECH_PARTNERS}/${techPartnerId}${TechPartnerRoute.AD_FORMAT_CREATE}`}
							>
								<Link
									data-testid='tech-partner-link-to-create-ad-format-form'
									to={`${AppRoute.TECH_PARTNERS}/${techPartnerId}${TechPartnerRoute.AD_FORMAT_CREATE}`}
								>
									<Plus size={16} strokeWidth={1.5} />
									Add Ad Format
								</Link>
							</StyledMenuActionItem>
						)}
						{isLoadingAdFormats ? (
							<LoadingIndicator />
						) : (
							adFormats.map(({ name, id }) => (
								<Menu.Item
									key={`${AppRoute.TECH_PARTNERS}/${techPartnerId}/ad-formats/${id}`}
								>
									<Link
										data-testid={`tech-partner-link-to-${id}-ad-formats-form`}
										to={`${AppRoute.TECH_PARTNERS}/${techPartnerId}/ad-formats/${id}`}
									>
										{name}
									</Link>
								</Menu.Item>
							))
						)}
					</StyledSubMenu>
					<StyledSubMenu title='Pages' key='Pages'>
						{isAdmin && (
							<StyledMenuActionItem
								key={`${AppRoute.TECH_PARTNERS}/${techPartnerId}${TechPartnerRoute.PAGE_CREATE}`}
							>
								<Link
									data-testid='tech-partner-link-to-create-page-form'
									to={`${AppRoute.TECH_PARTNERS}/${techPartnerId}${TechPartnerRoute.PAGE_CREATE}`}
								>
									<Plus size={16} strokeWidth={1.5} />
									Add Page
								</Link>
							</StyledMenuActionItem>
						)}
						{isLoadingPages ? (
							<LoadingIndicator />
						) : (
							pages.map(({ id, name }) => (
								<Menu.Item
									key={`${AppRoute.TECH_PARTNERS}/${techPartnerId}/pages/${id}`}
								>
									<Link
										data-testid={`tech-partner-link-to-${id}-page-form`}
										to={`${AppRoute.TECH_PARTNERS}/${techPartnerId}/pages/${id}`}
									>
										{name}
									</Link>
								</Menu.Item>
							))
						)}
					</StyledSubMenu>
				</StyledMenu>
			</StyledSider>
			<Layout>
				<StyledContent>
					<Switch>
						<Route path={path} exact>
							<TechPartnerDetails
								techPartnerName={techPartner?.name || ''}
								techPartnerDescription={techPartner?.description || ''}
							/>
						</Route>
						<RoleProtectedRoute
							allowedRoles={[UserRole.ADMIN]}
							redirectPath={url}
							path={`${path}${TechPartnerRoute.PAGE_CREATE}`}
							exact
						>
							<CreatePage
								techPartnerName={techPartner?.name || ''}
								onSuccess={loadPages}
							/>
						</RoleProtectedRoute>
						<RoleProtectedRoute
							allowedRoles={[UserRole.ADMIN]}
							redirectPath={url}
							path={`${path}${TechPartnerRoute.AD_FORMAT_CREATE}`}
							exact
						>
							<CreateAdFormat
								techPartnerName={techPartner?.name || ''}
								onSuccess={loadAdFormats}
							/>
						</RoleProtectedRoute>
					</Switch>
				</StyledContent>
			</Layout>
		</Layout>
	);
};

export default TechPartner;
