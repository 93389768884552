import { LoadingIndicatorWrapper, StyledSpin } from './LoadingIndicator.styled';

interface Props {
	fullScreen?: boolean;
}

const LoadingIndicator = ({ fullScreen }: Props) => (
	<LoadingIndicatorWrapper
		fullScreen={fullScreen}
		data-testid='loading-indicator'
	>
		<StyledSpin />
	</LoadingIndicatorWrapper>
);

LoadingIndicator.defaultProps = { fullScreen: false };

export default LoadingIndicator;
