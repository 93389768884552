export enum UserRole {
	ADMIN = 'admin',
	AD_OPS = 'adOps'
}

export interface User {
	username: string;
	roles: UserRole[];
	permissions: string[];
	first_name: string;
	last_name: string;
}
