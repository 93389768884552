import { ReactNode } from 'react';
import StyledAntButton from '../Button';
import StyledModal from './ConfirmationModal.styled';

interface Props {
	visible?: boolean;
	cancelText?: string;
	confirmText?: string;
	onCancel: () => void;
	onConfirm: () => void;
	children: ReactNode;
	title: string;
}

const ConfirmationModal = ({
	visible,
	cancelText,
	confirmText,
	onCancel,
	onConfirm,
	children,
	title
}: Props) => (
	<StyledModal
		width={1000}
		closable={false}
		visible={visible}
		data-testid='confirmation-modal'
		title={title}
		footer={[
			<StyledAntButton
				variant='secondary'
				data-testId='confirmation-modal-cancel-button'
				onClick={onCancel}
			>
				{cancelText}
			</StyledAntButton>,
			<StyledAntButton
				variant='primary'
				data-testId='confirmation-modal-confirm-button'
				onClick={onConfirm}
			>
				{confirmText}
			</StyledAntButton>
		]}
	>
		<span data-testid='confirmation-modal-content'>{children}</span>
	</StyledModal>
);

ConfirmationModal.defaultProps = {
	visible: false,
	cancelText: 'Cancel',
	confirmText: 'Yes, proceed'
};

export default ConfirmationModal;
