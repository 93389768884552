import { useContext } from 'react';
import { AuthContext } from 'contexts';
import { AuthActions, User, UserRole } from 'models';

export interface LoginCodeVerificationStepProps extends User {
	token: string;
}

interface ReturnUseAuth {
	logout: () => void;
	loginSuccess: ({
		username,
		roles,
		permissions,
		token,
		first_name,
		last_name
	}: LoginCodeVerificationStepProps) => void;
	user: User;
	isLoggedIn: boolean;
	isAdmin: boolean;
}

const useAuth = (): ReturnUseAuth => {
	const context = useContext(AuthContext);

	if (!context) {
		throw new Error(
			'Missing context provider (AuthProvider) in parent component.'
		);
	}

	const {
		state: { user, isLoggedIn },
		dispatch
	} = context;

	const loginSuccess = ({
		username,
		roles,
		permissions,
		token,
		first_name,
		last_name
	}: LoginCodeVerificationStepProps) => {
		dispatch({
			type: AuthActions.LOGIN_SUCCESS,
			payload: {
				username,
				roles,
				first_name,
				last_name,
				permissions,
				token
			}
		});
	};

	const logout = () => {
		dispatch({
			type: AuthActions.LOGOUT
		});
	};

	const isAdmin = user.roles.includes(UserRole.ADMIN);

	return {
		logout,
		loginSuccess,
		user,
		isLoggedIn,
		isAdmin
	};
};

export default useAuth;
