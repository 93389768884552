import styled from 'styled-components';

export const StyledWrapper = styled.div`
	background-color: ${({ theme }) => theme.colors.white};
	border-radius: ${({ theme }) => theme.border_radius};
	border: 1px solid rgba(218, 218, 237, 0.3);
`;

export const StyledHeader = styled.div`
	padding: 24px;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	color: ${({ theme }) => theme.colors.neutral_300};
	border-bottom: 1px solid rgba(218, 218, 237, 0.3);
`;

export const StyledContent = styled.div`
	padding: 24px 24px 0 24px;
	border-top: 1px solid rgba(218, 218, 237, 0.3);
`;
