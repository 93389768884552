import styled from 'styled-components';
import { Power, Edit } from 'react-feather';
import { Menu } from 'antd';

export const StyledActionMenuItem = styled(Menu.Item)`
	display: flex;
	align-items: center;
	text-align: left;
	height: 40px;
	padding: 12px 16px;
	line-height: 16px;
	box-sizing: border-box;
	border-radius: 8px;
	color: ${({ theme }) => theme.colors.placeholder};

	svg {
		margin-right: 12px;
		margin-left: -6px;
	}

	&:hover {
		background-color: ${({ theme }) => theme.colors.hover};
		color: ${({ theme }) => theme.colors.primary_1000};
	}
`;

export const StyledActionMenu = styled(Menu)`
	padding: 8px;
	background: ${({ theme }) => theme.colors.white};
	border: 1px solid ${({ theme }) => theme.colors.input_border};
	box-sizing: border-box;
	border-radius: 8px;
`;

export const StyledPowerIcon = styled(Power)`
	color: ${({ theme }) => theme.colors.text_light};
`;

export const StyledEditIcon = styled(Edit)`
	color: ${({ theme }) => theme.colors.text_light};
`;
