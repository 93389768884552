import React from 'react';
import { useHistory } from 'react-router-dom';
import {
	StyledActionMenu,
	StyledActionMenuItem,
	StyledEditIcon
} from 'components';
import { AppRoute } from 'routing';

interface Props {
	id: number;
}

const Menu = ({ id }: Props) => {
	const history = useHistory();

	const handleEditClick = () => {
		history.push(`${AppRoute.TECH_PARTNERS}/${id}`);
	};

	return (
		<StyledActionMenu
			data-testid='tech-partner-action-menu'
			prefixCls='ant-dropdown-menu'
		>
			<StyledActionMenuItem
				data-testid='action-menu-item-edit'
				key='edit'
				onClick={handleEditClick}
				icon={<StyledEditIcon size={16} />}
			>
				Edit
			</StyledActionMenuItem>
		</StyledActionMenu>
	);
};

export default Menu;
