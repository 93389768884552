import { Dropdown as AntDropdown } from 'antd';
import { MoreHorizontal } from 'react-feather';
import StyledDropdownButton from './TechPartnerDropdown.styled';
import Menu from './components';

interface Props {
	id: number;
}

const TechPartnerDropdown = ({ id }: Props) => (
	<AntDropdown overlay={<Menu id={id} />} trigger={['click']}>
		<StyledDropdownButton data-testid='tech-partner-action-menu-button'>
			<MoreHorizontal size={16} />
		</StyledDropdownButton>
	</AntDropdown>
);

export default TechPartnerDropdown;
