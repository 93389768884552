import React from 'react';
import { Dropdown as AntDropdown } from 'antd';
import { useAuth } from 'CustomHooks';
import {
	UserPanel,
	UserAvatar,
	UserInfo,
	UserName,
	UserRole,
	StyledDropdownButton,
	ArrowDown
} from './Dropdown.styled';

interface DropdownProps {
	/** Menu which will be displayed after click on dropdown */
	children: React.ReactElement;
}

const Dropdown = (props: DropdownProps) => {
	const { children: DropdownMenu } = props;
	const { user } = useAuth();

	return (
		<AntDropdown overlay={DropdownMenu} trigger={['click']}>
			<StyledDropdownButton data-testid='navbar-dropdown-button'>
				<UserPanel>
					<UserAvatar data-testid='navbar-user-avatar' />
					<UserInfo>
						<UserName data-testid='navbar-user-name'>{`${user.first_name} ${user.last_name}`}</UserName>
						<UserRole data-testid='navbar-user-role'>{user.roles[0]}</UserRole>
					</UserInfo>
					<ArrowDown />
				</UserPanel>
			</StyledDropdownButton>
		</AntDropdown>
	);
};

export default Dropdown;
