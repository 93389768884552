import axios, {
	AxiosRequestConfig,
	AxiosError,
	AxiosResponse,
	AxiosInstance
} from 'axios';
import { getPreservedToken } from 'contexts';
import { createNotification } from 'components';

export const authorizationRequestInterceptor = (
	config: AxiosRequestConfig
): AxiosRequestConfig => {
	const token = getPreservedToken();

	if (token) {
		return {
			...config,
			headers: {
				...config.headers,
				Authorization: `Token ${token}`
			}
		};
	}

	return config;
};

const errorHandler = (error: AxiosError): Promise<AxiosError> | void => {
	if (!error.response) {
		return createNotification({
			description: 'Connection error',
			notificationType: 'error'
		});
	}

	const { status } = error.response;
	switch (status) {
		case 403:
			return createNotification({
				description: 'Authentication credentials were not provided',
				notificationType: 'error'
			});
		case 500:
			return createNotification({
				description: 'Internal server error',
				notificationType: 'error'
			});
		case 503:
			return createNotification({
				description: 'Service unavailable',
				notificationType: 'error'
			});
		case 504:
			return createNotification({
				description: 'Gateway timeout',
				notificationType: 'error'
			});
		default:
			return Promise.reject(error);
	}
};

const responseHandler = (response: AxiosResponse): AxiosResponse => response;

export default function axiosInterceptors(): AxiosInstance {
	const baseURL = process.env.REACT_APP_BACKEND_HOST || 'http://localhost:8000';

	const axiosApiInstance = axios.create({
		baseURL,
		headers: {
			'Content-Type': 'application/json'
		}
	});

	axiosApiInstance.interceptors.request.use(
		authorizationRequestInterceptor,
		errorHandler
	);

	axiosApiInstance.interceptors.response.use(responseHandler, errorHandler);

	return axiosApiInstance;
}
