import { RefAttributes } from 'react';
import { Menu } from 'antd';
import { useAuth } from 'CustomHooks';
import {
	StyledActionMenu,
	StyledActionMenuItem,
	StyledPowerIcon
} from 'components';

const DropdownMenu = (props: RefAttributes<Menu>) => {
	const { logout } = useAuth();

	return (
		<StyledActionMenu
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}
			data-testid='navbar-dropdown-menu'
		>
			<StyledActionMenuItem
				data-testid='navbar-dropdown-menu-item-logout'
				key='logout'
				onClick={() => logout()}
				icon={<StyledPowerIcon size={16} />}
			>
				Log out
			</StyledActionMenuItem>
		</StyledActionMenu>
	);
};

export default DropdownMenu;
