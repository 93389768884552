import { ReactNode } from 'react';
import { ChevronDown, ChevronUp } from 'react-feather';
import {
	StyledIconsWrapper,
	StyledIconWrapper,
	StyledSortButton
} from './SortButton.styled';

interface Props {
	value: string;
	onChange: (newValue: string) => void;
	dataIndex: string;
	children: ReactNode;
}

const SortButton = ({ onChange, value, dataIndex, children }: Props) => {
	const descendingValue = `-${dataIndex}`;
	const handleChange = () => {
		const isCurrentIndex = value.includes(dataIndex);
		if (!isCurrentIndex) {
			onChange(dataIndex);
			return;
		}

		if (value === dataIndex) {
			onChange(descendingValue);
		} else {
			onChange('');
		}
	};
	return (
		<StyledSortButton
			onClick={handleChange}
			data-testid={`${dataIndex}-sort-button`}
		>
			{children}
			<StyledIconsWrapper>
				<StyledIconWrapper isActive={value === dataIndex}>
					<ChevronUp size={10} />
				</StyledIconWrapper>
				<StyledIconWrapper isActive={value === descendingValue}>
					<ChevronDown size={10} />
				</StyledIconWrapper>
			</StyledIconsWrapper>
		</StyledSortButton>
	);
};

export default SortButton;
