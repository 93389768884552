export interface LoginCredentialsStepResponse {
	ephemeral_token: string;
}

export interface LoginCredentialsStepError {
	non_field_errors: string[];
}

interface LoginCredentialsStepRequest {
	method: 'post';
	url: 'api/auth/login/';
	data: {
		username: string;
		password: string;
	};
}

export const loginCredentialsStepRequest = (
	username: string,
	password: string
): LoginCredentialsStepRequest => ({
	method: 'post',
	url: 'api/auth/login/',
	data: {
		username,
		password
	}
});
