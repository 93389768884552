import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { StyledInput } from 'components';

export const StyledNameLink = styled(NavLink)`
	color: inherit;
	&:hover {
		color: inherit;
	}
`;

export const StyledTableWrapper = styled.div`
	& .ant-table {
		background: none;

		table {
			border-spacing: 0 8px;

			td:not(:first-child) {
				text-align: right;
			}

			th {
				vertical-align: center;
				padding-bottom: 10px;
				padding-top: 16px;
				font-weight: bold;
				font-size: 14px;
				line-height: 16px;
				letter-spacing: 0.012em;
				color: ${({ theme }) => theme.colors.labels};
				border-top: 1px solid ${({ theme }) => theme.colors.primary_100};

				&:first-child {
					padding-left: 27px;
				}
				&:last-child {
					padding-right: 24px;
				}
				&:not(:first-child) {
					text-align: right;
				}
			}
		}

		.ant-table-container table > thead > tr:first-child {
			th {
				&:first-child,
				&:last-child {
					border-top-left-radius: 0;
					border-top-right-radius: 0;
				}
			}
		}
	}
`;

export const StyledSortButton = styled.button`
	border: none;
	background: none;
	padding: 0;
	font-weight: inherit;
	font-size: inherit;
	line-height: inherit;
	letter-spacing: inherit;
	color: inherit;
	cursor: pointer;
	div {
		margin-left: 8px;
	}
`;

export const StyledRow = styled.tr`
	&:nth-child(odd) {
		td {
			background: rgba(255, 255, 255, 0.8);
		}
	}

	&:nth-child(even) {
		td {
			background: rgba(255, 255, 255, 0.4);
		}
	}
`;

export const StyledCell = styled.td`
	border: 1px solid rgba(218, 218, 237, 0.3);
	font-size: 14px;
	line-height: 16px;
	letter-spacing: 0.012em;
	color: ${({ theme }) => theme.colors.placeholder};
	height: 40px;

	&:last-child {
		text-align: right;
		padding-right: 24px;
		border-left: none;
		border-radius: ${({ theme }) =>
			`0 ${theme.border_radius} ${theme.border_radius} 0`};

		button {
			margin-left: auto;
		}
	}

	&:not(:last-child):not(:first-child) {
		border-right: none;
		border-left: none;
	}

	&:first-child {
		padding-left: 27px;
		font-weight: bold;
		border-right: none;
		border-radius: ${({ theme }) =>
			`${theme.border_radius} 0 0 ${theme.border_radius}`};
	}
`;

export const StyledSearchInput = styled(StyledInput)`
	margin-left: auto;
	width: 193px;
	margin-bottom: 0;
	background: none;
	input {
		::placeholder {
			color: ${({ theme }) => theme.colors.selected_text};
		}
	}
`;
