import { Dropdown, DropdownMenu, Navigation } from './components';
import { Container, Logo, Separator, Wrapper } from './Navbar.styled';

const Navbar = () => (
	<Container>
		<Logo data-testid='navbar-logo' />
		<Wrapper>
			<Navigation />
			<Separator />
			<Dropdown>
				<DropdownMenu />
			</Dropdown>
		</Wrapper>
	</Container>
);

export default Navbar;
