import { AppRoute, RouteName } from 'routing';
import StyledNavLink from './Navigation.styled';

const Navigation = () => (
	<nav>
		<StyledNavLink to={AppRoute.INVENTORY} data-testid='inventory-page-link'>
			{RouteName.INVENTORY}
		</StyledNavLink>
		<StyledNavLink to={AppRoute.DEALS} data-testid='deals-page-link'>
			{RouteName.DEALS}
		</StyledNavLink>
		<StyledNavLink
			to={AppRoute.CREATIVE_APPROVAL}
			data-testid='creative-approval-page-link'
		>
			{RouteName.CREATIVE_APPROVAL}
		</StyledNavLink>
		<StyledNavLink to={AppRoute.REPORTS} data-testid='reports-page-link'>
			{RouteName.REPORTS}
		</StyledNavLink>
		<StyledNavLink to={AppRoute.RESOURCES} data-testid='resources-page-link'>
			{RouteName.RESOURCES}
		</StyledNavLink>
		<StyledNavLink
			to={AppRoute.TECH_PARTNERS}
			data-testid='tech-partners-page-link'
		>
			{RouteName.TECH_PARTNERS}
		</StyledNavLink>
	</nav>
);

export default Navigation;
