import styled from 'styled-components';
import { device } from 'theme';

export const StyledContainer = styled.div`
	justify-content: center;
	display: flex;
	padding: 44px 16px;

	@media ${device.tablet_small} {
		padding: 44px 32px;
	}
	@media ${device.laptop} {
		padding: 44px 88px;
	}
`;

export const StyledWrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;

	@media ${device.tablet_medium} {
		max-width: 840px;
	}
	@media ${device.laptop} {
		max-width: 100%;
	}
	@media ${device.laptop} {
		max-width: 1264px;
	}
`;

export const StyledTitle = styled.h2`
	margin-bottom: 40px;
	font-weight: bold;
	font-size: 24px;
	line-height: 28px;
	letter-spacing: 0.012em;
	color: ${({ theme }) => theme.colors.primary_1000};
`;
