import { Form, FormInstance, Input, Select } from 'antd';
import { ChevronDown } from 'react-feather';
import { ValidationMessage } from 'components';
import { FlexContainer, StyledFormItem } from './AdFormatForm.styled';
import ValidationMessages from './ValidationMessages';

interface Props {
	onChange: () => void;
	form?: FormInstance;
}

interface Options {
	label: string;
	value: string;
}

const AdFormatForm = ({ onChange, form }: Props) => {
	const options: Options[] = [
		{ label: 'Video', value: 'video' },
		{ label: 'Image', value: 'image' }
	];

	return (
		<Form
			form={form}
			data-testid='tech-partner-ad-format-form'
			layout='vertical'
			onFieldsChange={onChange}
			requiredMark={false}
		>
			<StyledFormItem
				label='Ad Format Name'
				name='name'
				rules={[
					{
						required: true,
						message: (
							<ValidationMessage validationMessage={ValidationMessages.name} />
						)
					}
				]}
			>
				<Input data-testid='tech-partner-ad-format-form-name-input' />
			</StyledFormItem>
			<StyledFormItem
				label='Content Type'
				name='content_type'
				rules={[
					{
						required: true,
						message: (
							<ValidationMessage validationMessage={ValidationMessages.type} />
						)
					}
				]}
			>
				<Select
					allowClear
					suffixIcon={<ChevronDown />}
					data-testid='tech-partner-ad-format-form-content-type-select'
					options={options}
				/>
			</StyledFormItem>
			<FlexContainer>
				<StyledFormItem
					name='min_width'
					label='Minimum Width'
					rules={[
						{
							required: true,
							message: (
								<ValidationMessage
									validationMessage={ValidationMessages.min_width}
								/>
							)
						}
					]}
				>
					<Input
						type='number'
						data-testid='tech-partner-ad-format-form-min-width-input'
					/>
				</StyledFormItem>
				<StyledFormItem
					name='max_width'
					label='Maximum Width'
					rules={[
						{
							required: true,
							message: (
								<ValidationMessage
									validationMessage={ValidationMessages.max_width}
								/>
							)
						}
					]}
				>
					<Input
						type='number'
						data-testid='tech-partner-ad-format-form-max-width-input'
					/>
				</StyledFormItem>
			</FlexContainer>
			<FlexContainer>
				<StyledFormItem
					name='min_height'
					label='Minimum Height'
					rules={[
						{
							required: true,
							message: (
								<ValidationMessage
									validationMessage={ValidationMessages.min_height}
								/>
							)
						}
					]}
				>
					<Input
						type='number'
						data-testid='tech-partner-ad-format-form-min-height-input'
					/>
				</StyledFormItem>
				<StyledFormItem
					label='Maximum Height'
					name='max_height'
					rules={[
						{
							required: true,
							message: (
								<ValidationMessage
									validationMessage={ValidationMessages.max_height}
								/>
							)
						}
					]}
				>
					<Input
						type='number'
						data-testid='tech-partner-ad-format-form-max-height-input'
					/>
				</StyledFormItem>
			</FlexContainer>
			<StyledFormItem
				name='ratio'
				label='Ratio'
				rules={[
					{
						required: true,
						message: (
							<ValidationMessage validationMessage={ValidationMessages.ratio} />
						)
					}
				]}
			>
				<Input data-testid='tech-partner-ad-format-form-ratio-input' />
			</StyledFormItem>
			<StyledFormItem
				name='ad_type'
				label='Ad Type'
				rules={[
					{
						required: true,
						message: (
							<ValidationMessage
								validationMessage={ValidationMessages.ad_type}
							/>
						)
					}
				]}
			>
				<Input data-testid='tech-partner-ad-format-form-ad-type-input' />
			</StyledFormItem>
			<StyledFormItem
				name='position'
				label='Position'
				rules={[
					{
						required: true,
						message: (
							<ValidationMessage
								validationMessage={ValidationMessages.position}
							/>
						)
					}
				]}
			>
				<Input data-testid='tech-partner-ad-format-form-position-input' />
			</StyledFormItem>
		</Form>
	);
};

AdFormatForm.defaultProps = {
	form: undefined
};

export default AdFormatForm;
