import { notification } from 'antd';
import { X } from 'react-feather';
import { NotificationType } from 'models';
import { StyledAlertTriangle, StyledInfo } from './Notification.styled';

interface NotificationProps {
	description: string;
	notificationType: NotificationType;
	autoClose?: boolean;
}

const createNotification = ({
	description,
	notificationType,
	autoClose
}: NotificationProps) => {
	const defaultDuration = 4; // in seconds

	const NotificationIcon =
		notificationType === 'success' ? (
			<StyledInfo data-testid='notification-success-icon' />
		) : (
			<StyledAlertTriangle data-testid='notification-error-icon' />
		);

	notification.open({
		message: '', // we don't need the message, but the message prop is required
		description,
		icon: NotificationIcon,
		closeIcon: <X data-testid='notification-close-icon' />,
		className: `custom-${notificationType}-notification`,
		duration: autoClose ? defaultDuration : 0 // 0 means that the notification will not disappear by itself
	});
};

export default createNotification;
