import { ReactNode } from 'react';
import {
	StyledContainer,
	StyledTitle,
	StyledWrapper
} from './ListViewWrapper.styled';

interface Props {
	title: string;
	children: ReactNode;
}

const ListViewWrapper = ({ title, children }: Props) => (
	<StyledContainer data-testid='list-view-wrapper'>
		<StyledWrapper>
			<StyledTitle>{title}</StyledTitle>
			{children}
		</StyledWrapper>
	</StyledContainer>
);

export default ListViewWrapper;
