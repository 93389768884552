import { Request } from '../request';

interface GetListRequest extends Request {
	params: {
		tech_partner: string;
	};
}

const getList = (id: string): GetListRequest => ({
	method: 'get',
	url: 'api/pages/',
	params: {
		tech_partner: id
	}
});

export default getList;
