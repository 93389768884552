import { Request } from '../request';

interface CreateRequest extends Request {
	data: {
		name: string;
		description: string;
		path: string;
		tech_partner: number;
	};
}

export interface CreateError {
	name?: string[];
	path?: string[];
	description?: string[];
	non_field_errors?: string[];
}

export const create = (
	name: string,
	description: string,
	path: string,
	tech_partner: number
): CreateRequest => ({
	method: 'post',
	url: 'api/pages/',
	data: {
		name,
		description,
		path,
		tech_partner
	}
});
