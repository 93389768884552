import styled from 'styled-components';
import { Form, Input } from 'antd';
import { Eye, EyeOff } from 'react-feather';
import { ReactComponent as purpleLogo } from 'assets/purpleLogo.svg';

const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100vw;
	height: 100vh;
	background: ${({ theme }) => theme.gradients.main_gradient};
`;

const StyledForm = styled(Form)`
	display: flex;
	flex-direction: column;
	width: 328px;
`;

const Logo = styled(purpleLogo)`
	width: 158px;
	margin-bottom: 16px;
`;

const StyledEye = styled(Eye)`
	color: ${({ theme }) => theme.colors.text_normal};
`;

const StyledEyeOff = styled(EyeOff)`
	color: ${({ theme }) => theme.colors.text_normal};
`;

const StyledInputPassword = styled(Input.Password)`
	.ant-input {
		padding-right: 25px;
	}

	svg:hover {
		color: ${({ theme }) => theme.colors.text_normal};
	}

	.ant-input-suffix {
		position: absolute;
		right: 9px;
		top: 13px;
	}
`;

export {
	Container,
	StyledForm,
	Logo,
	StyledEye,
	StyledEyeOff,
	StyledInputPassword
};
