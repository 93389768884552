import {
	MessageWrapper,
	StyledAlertTriangle
} from './ValidationMessage.styled';

interface ValidationMessageProps {
	validationMessage: string;
}

const ValidationMessage = ({ validationMessage }: ValidationMessageProps) => (
	<MessageWrapper>
		<StyledAlertTriangle />
		<span>{validationMessage}</span>
	</MessageWrapper>
);

export default ValidationMessage;
