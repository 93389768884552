import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { Button } from 'components';

export const StyledButton = styled(Button)`
	align-self: start;
	justify-self: end;
	grid-row: 1 / span 2;
	grid-column: 2;
`;

export const StyledBackNavLink = styled(NavLink)`
	display: flex;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 18px;
	letter-spacing: 0.012em;
	width: fit-content;
	color: ${({ theme }) => theme.colors.neutral_400};
	cursor: pointer;
	&:hover {
		color: ${({ theme }) => theme.colors.neutral_400};
	}
	img {
		width: 16px;
		margin-right: 4px;
	}
`;

export const StyledTitle = styled.h2`
	margin-top: 16px;
	margin-bottom: 0;
	font-style: normal;
	font-weight: bold;
	font-size: 24px;
	line-height: 28px;
	letter-spacing: 0.012em;
	color: ${({ theme }) => theme.colors.primary_1000};
`;

export const StyledHeader = styled.header`
	display: grid;
	grid-template-columns: 1fr 1fr;
	margin-bottom: 32px;
`;
