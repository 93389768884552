import styled from 'styled-components';
import { User, ChevronDown } from 'react-feather';

const StyledDropdownButton = styled.button`
	display: flex;
	align-items: center;
	text-align: left;
	height: 45px;
	margin: 0 24px;
	padding: 0;
	border: 0;
	background-color: transparent;
	cursor: pointer;
	outline: 0;
`;

const UserPanel = styled.div`
	display: flex;
	align-items: center;
`;

const UserAvatar = styled(User)`
	min-width: 35px;
	height: 33px;
	color: ${({ theme }) => theme.colors.white};
	background-color: rgba(255, 255, 255, 0.15);
	border-radius: 50%;
`;

const UserInfo = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 32px;
	margin: 0 8px;
`;

const UserName = styled.p`
	margin-bottom: 0;
	color: ${({ theme }) => theme.colors.white};
	font-size: ${({ theme }) => theme.fonts.small};
	font-weight: bold;
`;

const UserRole = styled.p`
	color: ${({ theme }) => theme.colors.white};
	font-size: ${({ theme }) => theme.fonts.small};
`;

const ArrowDown = styled(ChevronDown)`
	min-width: 20px;
	color: ${({ theme }) => theme.colors.white};
`;

export {
	StyledDropdownButton,
	UserPanel,
	UserAvatar,
	UserInfo,
	UserName,
	UserRole,
	ArrowDown
};
