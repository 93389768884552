import backIcon from 'assets/backIcon.svg';
import { AppRoute } from 'routing';
import {
	StyledButton,
	StyledBackNavLink,
	StyledTitle,
	StyledHeader
} from './ActionBar.styled';

interface Props {
	onSave?: () => void;
	title: string;
	disabled?: boolean;
	loading?: boolean;
}

const ActionBar = ({ onSave, title, disabled, loading }: Props) => (
	<StyledHeader data-testid='tech-partner-action-bar'>
		<StyledBackNavLink
			to={AppRoute.TECH_PARTNERS}
			data-testid='tech-partner-action-bar-back-to-list-button'
		>
			<img src={backIcon} alt='Go back to Tech Partners list' />
			<span>Tech Partners List</span>
		</StyledBackNavLink>
		<StyledButton
			variant='primary'
			htmlType='submit'
			onClick={onSave}
			data-testid='tech-partner-action-bar-save-button'
			disabled={disabled}
			loading={loading}
		>
			Save
		</StyledButton>
		<StyledTitle>{title}</StyledTitle>
	</StyledHeader>
);

ActionBar.defaultProps = {
	disabled: false,
	loading: false,
	onSave: () => {}
};

export default ActionBar;
