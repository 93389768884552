import { UserRole } from 'models';

const TOKEN_KEY = 'token';
const USER_DATA = 'userData';

interface UserData {
	username: string;
	first_name: string;
	last_name: string;
	roles: UserRole[];
	permissions: string[];
}

const getPreservedToken = (): string | null => localStorage.getItem(TOKEN_KEY);
const preserveToken = (token: string): void =>
	localStorage.setItem(TOKEN_KEY, token);
const removeToken = (): void => localStorage.removeItem(TOKEN_KEY);
const isTokenExisting = (): boolean => Boolean(localStorage.getItem(TOKEN_KEY));

const getPreservedUserData = (): UserData => {
	if (localStorage.getItem(USER_DATA) !== null) {
		return JSON.parse(localStorage.getItem(USER_DATA) || '');
	}

	return {
		username: '',
		first_name: '',
		last_name: '',
		roles: [],
		permissions: ['']
	};
};

const preserveUserData = ({
	username,
	first_name,
	last_name,
	roles,
	permissions
}: UserData): void => {
	const userDataJson = JSON.stringify({
		username,
		first_name,
		last_name,
		roles,
		permissions
	});

	localStorage.setItem(USER_DATA, userDataJson);
};

const removeUserData = (): void => localStorage.removeItem(USER_DATA);

export {
	getPreservedToken,
	preserveToken,
	removeToken,
	isTokenExisting,
	getPreservedUserData,
	preserveUserData,
	removeUserData,
	TOKEN_KEY,
	USER_DATA
};
