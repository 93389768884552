import styled from 'styled-components';

const StyledDropdownButton = styled.button`
	padding: 0;
	width: 24px;
	height: 24px;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid ${({ theme }) => theme.colors.neutral_200};
	color: ${({ theme }) => theme.colors.neutral_200};
	border-radius: 4px;
	background: transparent;
	cursor: pointer;

	&:hover {
		border: 1px solid ${({ theme }) => theme.colors.primary_600};
		color: ${({ theme }) => theme.colors.primary_600};
		box-shadow: ${({ theme }) => theme.box_shadows.shadow_2};
	}
`;

export default StyledDropdownButton;
