import styled from 'styled-components';
import { FormItem } from 'components';

const FlexContainer = styled.div`
	display: flex;
	justify-content: space-between;
	max-width: 520px;

	> div {
		max-width: 250px;
	}

	.ant-input {
		max-width: 250px;
	}
`;

const StyledFormItem = styled(FormItem)`
	margin-bottom: 24px;

	.ant-input {
		max-width: 520px;
	}
	.ant-select {
		max-width: 520px;
	}

	.ant-select:not(.ant-select-customize-input) .ant-select-selector {
		border-radius: 8px;
		height: 40px;
		align-items: center;
	}
	.ant-select-item-option-state {
		background: ${({ theme }) => theme.colors.active};
	}
`;

export { StyledFormItem, FlexContainer };
