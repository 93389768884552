import React from 'react';
import { Redirect } from 'react-router-dom';
import { AppRoute } from './AppRoute.enum';

interface ProtectedRoutesProps {
	children: React.ReactNode;
	isLoggedIn: boolean;
}

const ProtectedRoutes = ({ children, isLoggedIn }: ProtectedRoutesProps) =>
	isLoggedIn ? <>{children}</> : <Redirect to={AppRoute.LOGIN} />;

export default ProtectedRoutes;
