import { Request } from '../request';

interface CreateRequest extends Request {
	data: {
		name: string;
		content_type: string;
		min_width: number;
		max_width: number;
		min_height: number;
		max_height: number;
		ratio: string;
		ad_type: string;
		position: string;
		tech_partner: number;
	};
}

export interface CreateError {
	name?: string[];
	content_type?: string[];
	min_width?: string[];
	max_width?: string[];
	min_height?: string[];
	max_height?: string[];
	ratio?: string[];
	ad_type?: string[];
	position?: string[];
	non_field_errors?: string[];
}

export const create = (
	name: string,
	content_type: string,
	min_width: number,
	max_width: number,
	min_height: number,
	max_height: number,
	ratio: string,
	ad_type: string,
	position: string,
	tech_partner: number
): CreateRequest => ({
	method: 'post',
	url: 'api/ad-formats/',
	data: {
		name,
		content_type,
		min_width,
		max_width,
		min_height,
		max_height,
		ratio,
		ad_type,
		position,
		tech_partner
	}
});
