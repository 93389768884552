import { Form } from 'antd';
import styled from 'styled-components';
import { FormItem, Button } from 'components';
import { ReactComponent as MailImg } from 'assets/mailImg.svg';
import { ReactComponent as purpleLogo } from 'assets/purpleLogo.svg';

const Container = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100vw;
	height: 100vh;
	background: ${({ theme }) => theme.gradients.main_gradient};
`;

const MailImageWrapper = styled.div`
	position: relative;
	top: 65px;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const StyledMailImg = styled(MailImg)`
	margin-bottom: 15px;
`;

const VerificationWrapper = styled.div`
	width: 315px;
	margin-left: 5px;
`;

const Header = styled.h1`
	font-size: ${({ theme }) => theme.fonts.extra_large};
	font-weight: normal;
`;

const VerificationInfo = styled.p`
	margin: 18px 0 24px 0;
	color: ${({ theme }) => theme.colors.text_dark};
	line-height: 16px;
`;

const StyledForm = styled(Form)`
	display: flex;
`;

const StyledFormItem = styled(FormItem)`
	.ant-input {
		box-sizing: border-box;
		width: 223px;
		height: 40px;
		padding: 12px 16px;
		background: ${({ theme }) => theme.colors.white};
		border: 1px solid ${({ theme }) => theme.colors.input_border};
		border-radius: 8px 0 0 8px;
	}
`;

const VerifyButton = styled(Button)`
	width: 80px;
	padding: 0;
	border-radius: 0 8px 8px 0;
`;

const Logo = styled(purpleLogo)`
	width: 158px;
	margin-bottom: 54px;
	cursor: pointer;
`;

export {
	Container,
	MailImageWrapper,
	StyledMailImg,
	VerificationWrapper,
	Header,
	VerificationInfo,
	StyledForm,
	StyledFormItem,
	VerifyButton,
	Logo
};
